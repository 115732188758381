body {
  height: 100vh;
  width: 100vw;
  background-color: transparent !important;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 2000px) {
  body {
    padding: 12px;
  }
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
