@media screen and (max-width: 600px) {
  .hide-on-mobile {
    display: none;
  }
}

@media screen and (min-width: 1000px) {
  .hide-on-desktop {
    display: none;
  }
}

.height100 {
  height: 100%;
}
